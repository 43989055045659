import React from 'react';

import ScrollAnimation from 'react-animate-on-scroll';

import LevelUpImage1 from '@core/assets/images/level-up-pharmuni-1.png';
import LevelUpImage2 from '@core/assets/images/level-up-pharmuni-2.png';
import LevelUpImage3 from '@core/assets/images/level-up-pharmuni-3.png';
import LevelUpImage4 from '@core/assets/images/level-up-pharmuni-4.png';
import LevelUpImage5 from '@core/assets/images/level-up-pharmuni-5.png';

import classes from './LevelUp.module.scss';

const LevelUp = () => {
    return (
        <div className={`${classes['level-up-background']}`}>
            <div className={`${classes['level-up-wrapper']} container`}>
                <ScrollAnimation animateIn="fadeInUp">
                    <h2>
                        Elevate your career to new heights
                    </h2>
                    <h3>
                        We provide the vital tools to help you take the next step in your pharmaceutical and biotech career.
                    </h3>
                </ScrollAnimation>
                <div className="row pt-4">
                    <div className="col-xl-6 col-12">
                        <ScrollAnimation animateIn="fadeIn" delay={300}>
                            <a href='https://pharmuni.com/skill-tree/'>
                                <div className={`${classes['level-up-card']} ${classes['first']} ${classes['boost']}`}>
                                    <div>
                                        <h4>Track Your Progress with our AI-powered Skill Tree </h4>
                                    </div>
                                    <img src={LevelUpImage1.src} alt='Skill Tree' />
                                </div>
                            </a>
                        </ScrollAnimation>
                    </div>
                    <div className="col-xl-6 col-12 mt-4 mt-xl-0">
                        <ScrollAnimation animateIn="fadeIn" delay={600}>
                            <a href='https://pharmuni.com/resume-builder/'>
                                <div className={`${classes['level-up-card']} ${classes['first']} ${classes['profile']}`}>
                                    <div>
                                        <h4>Professional Introduction with Public Profile </h4>
                                    </div>
                                    <img src={LevelUpImage2.src} alt='Professional Introduction with Public Profile' />
                                </div>
                            </a>
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-xl-4 col-12">
                        <ScrollAnimation animateIn="fadeIn" delay={800}>
                            <div className={`${classes['level-up-card']} ${classes['second']} ${classes['chart']}`}>
                                <h4>1-1 Consultation Meeting </h4>
                                <img src={LevelUpImage3.src} alt='Prepare yourself for the job market' />
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-xl-4 col-12 mt-4 mt-xl-0">
                        <ScrollAnimation animateIn="fadeIn" delay={1000}>
                            <a href='https://pharmuni.com/cover-letter-builder/'>
                                <div className={`${classes['level-up-card']} ${classes['second']} ${classes['chart']}`}>
                                    <h4 className='mb-4'>Intelligent Introduction with Cover Letter</h4>
                                    <img src={LevelUpImage4.src} alt='Prepare yourself for the job market' />
                                </div>
                            </a>
                        </ScrollAnimation>
                    </div>
                    <div className="col-xl-4 col-12 mt-4 mt-xl-0">
                        <ScrollAnimation animateIn="fadeIn" delay={1200}>
                            <div className={`${classes['level-up-card']} ${classes['second']}`}>
                                <h4 className='mb-4'>Job Board with Smooth Collaboration with Recruiter</h4>
                                <img src={LevelUpImage5.src} alt='Prepare yourself for the job market' />
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LevelUp;